import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchPaymentDetails,
  fetchPaymentDetailById,
  fetchSubscriptionById,
  postSubscriptionById,
} from "services/payments";
import {
  initialPaymentDetail,
  initialPaginate,
} from "constants/initialPaymentDetails";
import { fetchWrapper } from "services/login";

export const getPaymentDetails = createAsyncThunk(
  "payments/getAll",
  async (params) => {
    try {
      const res = await fetchWrapper(fetchPaymentDetails, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? "Get all payments failed");
    }
  }
);

export const getPaymentDetailById = createAsyncThunk(
  "payments/getPaymentDetailById",
  async (params) => {
    try {
      const res = await fetchWrapper(fetchPaymentDetailById, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? "Get payment by id failed");
    }
  }
);

export const getSubscriptionById = createAsyncThunk(
  "payments/getSubscriptionById",
  async (params) => {
    try {
      const res = await fetchWrapper(fetchSubscriptionById, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? "Get subscription by id failed");
    }
  }
);

export const updateSubscriptionById = createAsyncThunk(
  "payments/updateSubscriptionById",
  async (params) => {
    try {
      const res = await fetchWrapper(postSubscriptionById, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? "Update subscription by id failed");
    }
  }
);

const initialState = {
  paymentDetails: initialPaginate.list,
  pageIndex: 0,
  pageSize: 0,
  totalCount: 0,
  paymentsLoading: false,
  paymentsError: null,
  isLoading: false,
  isError: null,
  currentPaymentDetail: initialPaymentDetail,
  currentSubscription: {},
};

const paymentSlice = createSlice({
  name: "paymentSlice",
  initialState,
  reducers: {
    resetPayment: (state) => {
      state.currentPaymentDetail = initialPaymentDetail;
      state.paymentDetails = initialPaginate.list;
      state.pageIndex = initialPaginate.pageIndex;
      state.pageSize = initialPaginate.pageSize;
      state.totalCount = initialPaginate.totalCount;
    },
    selectPayment: (state, { payload }) => {
      state.currentPaymentDetail = payload;
    },
  },
  extraReducers: {
    [getPaymentDetails.pending]: (state) => {
      state.paymentsLoading = true;
      state.paymentsError = null;
    },
    [getPaymentDetails.fulfilled]: (state, { payload }) => {
      state.paymentsLoading = false;
      state.paymentDetails = payload.list;
      state.pageIndex = payload.pageIndex;
      state.pageSize = payload.pageSize;
      state.totalCount = payload.totalCount;
      state.paymentsError = null;
    },
    [getPaymentDetails.rejected]: (state, action) => {
      state.paymentsLoading = false;
      state.paymentDetails = initialPaginate.list;
      state.pageIndex = initialPaginate.pageIndex;
      state.pageSize = initialPaginate.pageSize;
      state.totalCount = initialPaginate.totalCount;
      state.paymentsError = action.error.message;
    },
    [getPaymentDetailById.pending]: (state) => {
      state.paymentDetails = [];
      state.currentPaymentDetail = initialPaymentDetail;
      state.isLoading = true;
      state.isError = null;
    },
    [getPaymentDetailById.fulfilled]: (state, { payload }) => {
      state.paymentDetails = [payload];
      state.currentPaymentDetail = payload;
      state.isError = null;
      state.isLoading = false;
    },
    [getPaymentDetailById.rejected]: (state, action) => {
      state.paymentDetails = [];
      state.currentPaymentDetail = initialPaymentDetail;
      state.isLoading = false;
      state.isError = action.error.message;
    },
    [getSubscriptionById.pending]: (state) => {
      state.isLoading = true;
      state.isError = null;
    },
    [getSubscriptionById.fulfilled]: (state, { payload }) => {
      state.currentSubscription = payload;
      state.isError = null;
      state.isLoading = false;
    },
    [getSubscriptionById.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = action.error.message;
    },
    [updateSubscriptionById.pending]: (state, action) => {
      state.isLoading = true;
      state.isError = null;
    },
    [updateSubscriptionById.fulfilled]: (state) => {
      state.isError = null;
      state.isLoading = false;
    },
    [updateSubscriptionById.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = action.error.message;
    },
  },
});

export const { resetPayment, selectPayment } = paymentSlice.actions;

const { reducer } = paymentSlice;
export default reducer;

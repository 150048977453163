import handleResponse from "helpers/handleAPIResponse";
import { isNil } from "ramda";

const baseUrl = process.env.REACT_APP_BASE_URL;

export const fetchPaymentDetails = ({
  type,
  q,
  month,
  year,
  pageIndex,
  pageSize,
}) => {
  let searchBaseUrl = `${baseUrl}/paymentdetail?${type}=${q}`;
  searchBaseUrl += `&pageIndex=${pageIndex}&pageSize=${pageSize}`;
  if (!isNil(month)) searchBaseUrl += `&month=${month}`;
  if (!isNil(year)) searchBaseUrl += `&year=${year}`;

  console.log(searchBaseUrl);

  return fetch(searchBaseUrl, {
    method: "GET",
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.isSuccessful) {
        throw new Error(result.message.message);
      } else {
        return result.result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? "Get all payment details failed");
    });
};

export const fetchPaymentDetailById = ({ id }) => {
  return fetch(`${baseUrl}/paymentdetail/${id}`, {
    method: "GET",
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.isSuccessful) {
        throw new Error(result.message.message);
      } else {
        return result.result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? "Get payment detail by id failed");
    });
};

export const fetchSubscriptionById = ({ userId }) => {
  return fetch(`${baseUrl}/subscription?userId=${userId}`, {
    method: "GET",
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.isSuccessful) {
        throw new Error(result.message.message);
      } else {
        return result.result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? "Get subscription by id failed");
    });
};

export const postSubscriptionById = (params) => {
  const { id } = params;
  return fetch(`${baseUrl}/paymentdetail/${id}/subscription`, {
    method: "POST",
    body: JSON.stringify(params),
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.isSuccessful) {
        throw new Error(result.message.message);
      } else {
        return result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? "Update subscription by id failed");
    });
};

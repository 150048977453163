import { Router, Switch, Route } from "react-router-dom";
import { createBrowserHistory } from "history";
import Login from "pages/Login/Login";
import { lazy } from "react";
import RouteRequiresLogin from "components/Shared/RouteRequiresLogin";

const SearchResult = lazy(() => import("./pages/SearchResult/SearchResult"));
const PaymentDetail = lazy(() => import("./pages/PaymentDetail/PaymentDetail"));
const Search = lazy(() => import("./pages/Search/Search"));
const Summary = lazy(() => import("./pages/Summary/Summary"));

export const history = createBrowserHistory({
  basename: "/",
});

const Routes = () => {
  return (
    <Router history={history}>
      <Switch>
        <RouteRequiresLogin
          exact
          path="/paymentDetail/:id"
          component={PaymentDetail}
        ></RouteRequiresLogin>
        <RouteRequiresLogin
          exact
          path="/search"
          component={SearchResult}
        ></RouteRequiresLogin>
        <RouteRequiresLogin
          exact
          path="/summary"
          component={Summary}
        ></RouteRequiresLogin>
        <RouteRequiresLogin
          exact
          path="/"
          component={Search}
        ></RouteRequiresLogin>
        <Route exact path="/login">
          <Login />
        </Route>
      </Switch>
    </Router>
  );
};

export default Routes;
